
import moment from 'moment';

import { Component, Prop, Vue } from '@/lib/decorator';
import { formatPhone } from '@/lib/Utils';
import { convertRoleToText } from '@/lib/util/roles';
import { EmployeeDuplicateModel } from '@/interfaces/models/employees.interface';
import FormButton from '@/components/form/Button/index.vue';
import { GuiFormCheckbox } from '@library/gigant_ui';

@Component({
  components: {
    GuiFormCheckbox,
    FormButton,
  },
  emits: ['delete'],
})
export default class DuplicateModalContent extends Vue {
  @Prop({ required: true }) currentCard: EmployeeDuplicateModel = {
    id: 0,
    name: '',
    geoRegion: '',
    phone: '',
    email: '',
    role: '',
    createdAt: '',
    inn: '',
  };
  @Prop({ required: true }) duplicateCard: EmployeeDuplicateModel = {
    id: 0,
    name: '',
    geoRegion: '',
    phone: '',
    email: '',
    role: '',
    createdAt: '',
    inn: '',
  };
  @Prop({ default: false }) canEdit?: boolean;

  canProceed = false;

  proceed(): void {
    if (this.canProceed) {
      this.$emit('delete', this.duplicateCard.id);
    }
  }

  formatPhone(phone: string): string {
    return formatPhone(phone);
  }

  formatDate(date: string): string {
    return moment(date).format('DD.MM.YYYY HH:mm');
  }

  convertRoleToText(role: string, createdAt: string): string {
    return convertRoleToText(role) + (createdAt.length ? ' с ' + createdAt : '');
  }
}
