export function convertRoleToText(role: string) {
  switch (role) {
    case 'ROLE_EMPLOYEE': {
      return 'Активен';
    }
    case 'ROLE_AWAITING': {
      return 'Ожидает';
    }
    case 'ROLE_DEACTIVATED': {
      return 'Заблокирован';
    }
    case 'ROLE_ONBOARDING': {
      return 'Авторизован'; //'Онбординг';
    }
    case 'ROLE_REJECTED': {
      return 'Авторизован'; //'Отсеян';
    }
  }

  return 'В процессе создания';
}
