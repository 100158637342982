
import AppFieldset from '@/components/ui/Fieldset/Fieldset.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';

import EmployeeEntityModule, { EmployeeComment } from '@/store/employees/entity';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { strings } from '@/lib/stringConst';

import { getSuggestionBanksByBic } from '@/api/daData';
import { getRegionsGeoAll } from '@/api/region';
import { getSplitAll } from '@/api/split';

import { getAllCompetenceList } from '@/api/competence';
import { getCitizenshipsAll } from '@/api/citizenship';
import { getAllPartnersList } from '@/api/partners';
import { CompetenceEntity } from '@/interfaces/models/competence.interface';
import { PartnerSelectListItem } from '@/interfaces/partner.interface';

import { getVaccinationsAll, sendRocketWorkRequest } from '@/api/employees';

import ModalBlock from '@/components/Modal.vue';
import DuplicateModalContent from '@/views/employees/profile/_duplicate.vue';

import { clearPhone, formatCardNumber, formatPhoneWithBrackets } from '@/lib/Utils';

import { dateFullFormat } from '@/lib/Utils';
import userModule from '@/store/user';
import { EmployeeDuplicateModel, EmployeeTextFields } from '@/interfaces/models/employees.interface';
import { computed, defineComponent, onBeforeMount, reactive, ref, watch } from 'vue';
import { CatchFormResponse } from '@/interfaces/shared';
import { DropdownItem } from '@library/gigant_ui/dist/types/interfaces/dropdownItem';
import {
  GuiButton,
  GuiCheckboxGroup,
  GuiDatePicker,
  GuiDropdown,
  GuiRadioGroup,
  GuiTextField,
  GuiTextInput,
  GuiAutocomplete,
} from '@library/gigant_ui';
import { RadioGroupItem } from '@library/gigant_ui/dist/types/interfaces/radioGroupItem';
import { CheckboxGroupItem } from '@library/gigant_ui/dist/types/interfaces/checkboxGroupItem';
import Loading from '@/components/Loading.vue';
import moment from 'moment';
import validator from '@/lib/validator';
import useVuelidate from '@vuelidate/core';
import { DaDataSearchItem, BicSearchItem, BankSearchItem } from '@/interfaces/bankResponse.interface';
import { geoRegionEntity } from '@/interfaces/regionResponse.interface';

interface EmployeeStateParams {
  genders: RadioGroupItem[];
  citizenshipItems: DropdownItem[];
  geoRegionItems: (DropdownItem & { timeZone: number })[];
  partnerItems: DropdownItem[];
  competenceItemsAll: {
    id: string;
    name: string;
  }[];
  competenceItemsFiltered: DropdownItem[];
  roleItems: DropdownItem[];
  paymentTypeItems: DropdownItem[];
  splitItems: DropdownItem[];
  vaccinationItems: DropdownItem[];
  bicItems: BicSearchItem[];
  bicLoading: boolean;
  bankItems: BankSearchItem[];
  bankLoading: boolean;
}

interface EmployeeForm {
  lastName: string;
  firstName: string;
  middleName: string;
  birthday: Date | null;
  gender: string;
  belongingToCitizenship: string;
  geoRegion: string;
  phone: string;
  email: string;
  partnerUuid: string;
  competence: string[];
  lmk: Date | null;
  lmkNumber: string;
  role: string;
  split: string;
  vaccinationStatus: string;
  underageLink: string;
  sleeping: boolean;
  isTest: boolean;
  rocketWorkId: string;
  rocketWorkStatus: string;
  humanRocketWorkStatus: string;
  inn: string;
  bank: string;
  paymentAccount: string;
  paymentType: string;
  cardNumber: string;
  ks: string;
  bic: string;
  isSelfEmployed: [CheckboxGroupItem];
  underage: [CheckboxGroupItem];
  hasMobileApp: boolean;
  onDemandPaymentEnabled: boolean;
  docs: Array<CheckboxGroupItem & { key: string }>;
  other: Array<CheckboxGroupItem & { key: string }>;
  reliabilityItems: Array<CheckboxGroupItem & { key: string }>;
  verificationUpdatedAt: Date | null;
  selfEmployedDay: Date | null;
  reliabilityUpdatedAt: Date | null;
  checkReliability: [CheckboxGroupItem];
}

interface EmployeeState {
  isLoading: boolean;
  comments: EmployeeComment[];
  commentsTotalCount: number;
  isCommentAdd: boolean;
  isCommentEdit: boolean;
  commentText: string;
  commentError: string;
  commentEditId: string;
  deleteCommentModalShown: boolean;
  deleteDoubleId: number;
  employeeId: string;
  sleeping: boolean;
  splitInfo: string;
  createdAt: string;
  updatedAt: string;
  lastCameOut: string;
  geoRegion: string;
  timeZone: number;
}

export default defineComponent({
  name: 'EmployeeForm',
  emits: ['returnBefore'],
  components: {
    GuiTextField,
    GuiButton,
    GuiCheckboxGroup,
    GuiDropdown,
    GuiRadioGroup,
    GuiDatePicker,
    GuiTextInput,
    GuiAutocomplete,
    ModalBlock,
    DuplicateModalContent,
    AppFieldset,
    AppRow,
    AppCol,
    Loading,
  },
  props: {
    employeeId: {
      type: String,
      default: '',
    },
  },
  computed: {
    canEditEmployee(): boolean {
      return userModule.userHasPermission('CAN_EDIT_EMPLOYEE');
    },
    userCanDeleteDuplicate(): boolean {
      return userModule.userHasPermission('CAN_DELETE_EMPLOYEE_DOUBLES');
    },
    isSupervisor(): boolean {
      return userModule.isSupervisor;
    },
    showLoadMoreCommentsButton(): boolean {
      return EmployeeEntityModule.commentsTotalCount > EmployeeEntityModule.commentsCurrentCount;
    },
    isShowRocketWorkButton(): boolean {
      return [
        'not_selfemployed',
        'just_sent_inn_for_check',
        'need_pervisions',
        'need_permissions',
        'unknown',
        'pending',
        'validated',
      ].includes(EmployeeEntityModule.model.rocketWorkStatus);
    },
    duplicateCard(): EmployeeDuplicateModel {
      return EmployeeEntityModule.duplicate;
    },
    currentCard(): EmployeeDuplicateModel {
      return {
        id: 0,
        name:
          this.employeeForm.lastName +
          ' ' +
          this.employeeForm.firstName +
          ' ' +
          this.employeeForm.middleName +
          ' ' +
          moment(this.employeeForm.birthday).format('YYYY-MM-DD'),
        geoRegion: this.employeeForm.geoRegion,
        phone: clearPhone(this.employeeForm.phone),
        email: this.employeeForm.email,
        role: this.employeeForm.role,
        createdAt: this.state.createdAt,
        inn: this.employeeForm.inn,
      };
    },
    showDuplicateModal() {
      return EmployeeEntityModule.showDuplicateModal;
    },
  },
  setup(props, { emit }) {
    const employeeForm: EmployeeForm = reactive({
      lastName: '',
      firstName: '',
      middleName: '',
      birthday: null,
      gender: '',
      belongingToCitizenship: '',
      geoRegion: '',
      phone: '',
      email: '',
      partnerUuid: '',
      competence: [],
      lmk: null,
      lmkNumber: '',
      role: '',
      split: '',
      vaccinationStatus: 'unknown',
      underageLink: '',
      sleeping: false,
      isTest: false,
      rocketWorkId: '',
      rocketWorkStatus: '',
      humanRocketWorkStatus: '',
      inn: '',
      bank: '',
      paymentAccount: '',
      paymentType: '',
      cardNumber: '',
      ks: '',
      bic: '',
      hasMobileApp: true,
      onDemandPaymentEnabled: false,
      isSelfEmployed: [{ label: 'Самозанятость подтверждена', value: false }],
      underage: [{ label: 'Есть согласие родителя', value: false }],
      checkReliability: [{ label: 'Проверен', value: false }],
      docs: [
        { label: 'Паспортные данные', value: false, key: 'passport' },
        { label: 'ИНН', value: false, key: 'inn', disabled: true },
        { label: 'ЛМК', value: false, key: 'lmk', disabled: true },
        { label: 'СНИЛС', value: false, key: 'snils' },
      ],
      reliabilityItems: [
        { label: 'Задолженность по НПД', value: false, key: 'debtNpd' },
        { label: 'Непогашенная судимость', value: false, key: 'criminalRecord' },
        { label: 'Судебное разбирательство', value: false, key: 'trial' },
        { label: 'Спор', value: false, key: 'dispute' },
      ],
      reliabilityStatus: 'cond_reliability',
      other: [
        { label: 'Использует приложение', value: false, key: 'hasMobileApp' },
        { label: 'В спящем режиме', value: false, key: 'sleeping' },
        { label: 'Тестовый исполнитель', value: false, key: 'isTest' },
        {
          label: 'Общий исполнитель',
          value: userModule.isSupervisor || !!(userModule.partner?.isAgent && !userModule.partner?.isSupplier),
          key: 'isSharing',
        },
        { label: 'Доступны выплаты on-demand', value: false, key: 'onDemandPaymentEnabled' },
      ],
      verificationUpdatedAt: null,
      selfEmployedDay: null,
      reliabilityUpdatedAt: null,
    });

    const state: EmployeeState = reactive({
      isLoading: true,
      comments: [],
      commentsTotalCount: 0,
      isCommentAdd: false,
      isCommentEdit: false,
      commentText: '',
      commentError: '',
      commentEditId: '',
      deleteCommentModalShown: false,
      deleteDoubleId: 0,
      employeeId: props.employeeId,
      sleeping: false,
      splitInfo: '',
      createdAt: '',
      updatedAt: '',
      lastCameOut: '',
      geoRegion: '',
      timeZone: 0,
    });

    const stateParams: EmployeeStateParams = reactive({
      genders: [
        {
          label: 'Мужской',
          nativeValue: 'm',
          disabled: false,
        },
        {
          label: 'Женский',
          nativeValue: 'f',
          disabled: false,
        },
      ],
      citizenshipItems: [],
      geoRegionItems: [],
      partnerItems: [],
      competenceItemsAll: [],
      competenceItemsFiltered: [],
      roleItems: [],
      paymentTypeItems: [],
      splitItems: [],
      vaccinationItems: [],
      bicItems: [],
      bicLoading: false,
      bankItems: [],
      bankLoading: false,
    });

    const vRulesForFio = {
      regExp: validator({
        name: 'regexp',
        message: 'Неверный формат',
        attributes: /^[А-Яа-яЁёЪъ\- ]+$/,
      }),
      regExp2: validator({
        name: 'unRegexp',
        message: 'Неверный формат',
        attributes: /( -)/g,
      }),
      regExp3: validator({
        name: 'unRegexp',
        message: 'Неверный формат',
        attributes: /(- )/g,
      }),
    };
    const validationRules = computed(() => {
      const localRules: Record<string, Record<string, ReturnType<typeof validator>>> = {
        lastName: {
          required: validator({ name: 'required' }),
          ...vRulesForFio,
        },
        firstName: {
          required: validator({ name: 'required' }),
          ...vRulesForFio,
        },
        middleName: {
          ...vRulesForFio,
        },
        geoRegion: {
          required: validator({ name: 'required' }),
        },
        birthday: {
          dateValid: validator({
            name: 'dateValid',
            message: 'Некорректная дата (возраст от 14 до 100)',
            attributes: {
              format: 'YYYY-MM-DD',
              yearRangeMin: 14,
              yearRangeMax: 100,
            },
          }),
        },
        belongingToCitizenship: {
          required: validator({ name: 'required' }),
        },
        phone: {
          required: validator({ name: 'required' }),
          regExp: validator({
            name: 'regexp',
            message: 'Некорректный номер телефона',
            attributes: /\+7\(\d{3}\)\d{3}-\d{2}-\d{2}/,
          }),
        },
        email: {
          email: validator({ name: 'email' }),
          maxLength: validator({ name: 'maxLength', attributes: 180 }),
        },
        lmkNumber: {
          regExp: validator({
            name: 'regexp',
            message: 'Неверный формат номера ЛМК',
            attributes: /^(?!^0+$)\d{1,8}$/,
          }),
        },
        role: {
          required: validator({ name: 'required' }),
        },
        vaccinationStatus: {
          required: validator({ name: 'required' }),
        },
        inn: {
          regExp: validator({
            name: 'regexp',
            message: 'Неверный формат ИНН (12 цифр)',
            attributes: /\d{12}/,
          }),
          maxLength: validator({
            name: 'maxLength',
            message: 'Неверный формат ИНН (12 цифр)',
            attributes: 12,
          }),
        },
        paymentAccount: {
          regExp: validator({
            name: 'regexp',
            message: 'Некорректный расчетный счёт (20 цифр). Должен начинаться с цифры 4.',
            attributes: /(^4\d{19})|[_]{20}/,
          }),
          maxLength: validator({
            name: 'maxLength',
            message: 'Некорректный расчетный счёт (20 цифр)',
            attributes: 20,
          }),
        },
        cardNumber: {
          regExp: validator({
            name: 'regexp',
            message: 'Некорректный номер карты (16 цифр)',
            attributes: /(\d{4} \d{4} \d{4} \d{4})|([_]{4} [_]{4} [_]{4} [_]{4})/,
          }),
          maxLength: validator({
            name: 'maxLength',
            message: 'Некорректный номер карты (16 цифр)',
            attributes: 19,
          }),
        },
        paymentType: {
          required: validator({ name: 'required' }),
        },
        ks: {
          regExp: validator({
            name: 'regexp',
            message: 'Некорректный к/с (20 цифр)',
            attributes: /\d{20}|[_]{20}/,
          }),
          maxLength: validator({
            name: 'maxLength',
            message: 'Некорректный к/с (20 цифр)',
            attributes: 20,
          }),
        },
        bic: {
          regExp: validator({
            name: 'regexp',
            message: 'Некорректный БИК (9 цифр)',
            attributes: /\d{9}/,
          }),
          maxLength: validator({
            name: 'maxLength',
            message: 'Некорректный БИК (9 цифр)',
            attributes: 9,
          }),
        },
      };

      if (employeeForm.underage) {
        localRules.underageLink = {
          url: validator({ name: 'url' }),
        };
      }

      if (employeeForm.role === 'ROLE_DEACTIVATED') {
        localRules.split = {
          required: validator({ name: 'required' }),
        };
      }

      if (employeeForm.paymentType === 'sbp_account') {
        localRules.bic = {
          required: validator({ name: 'required' }),
          regExp: validator({
            name: 'regexp',
            message: 'Некорректный БИК (9 цифр)',
            attributes: /\d{9}/,
          }),
          maxLength: validator({
            name: 'maxLength',
            message: 'Некорректный БИК (9 цифр)',
            attributes: 9,
          }),
        };
      }

      if (employeeForm.paymentType === 'card') {
        if (employeeForm.cardNumber === '____ ____ ____ ____') {
          employeeForm.cardNumber = '';
        }
        localRules.cardNumber = {
          required: validator({ name: 'required' }),
          regExp: validator({
            name: 'regexp',
            message: 'Некорректный номер карты (16 цифр)',
            attributes: /(\d{4} \d{4} \d{4} \d{4})/,
          }),
          maxLength: validator({
            name: 'maxLength',
            message: 'Некорректный номер карты (16 цифр)',
            attributes: 19,
          }),
        };
      }

      return localRules;
    });
    const v$ = useVuelidate(validationRules, employeeForm);

    onBeforeMount(async () => {
      await getAllCitizenShips();
      await getAllGeoRegions();
      await getAllPartners();
      await getAllCompetence();
      await getAllRoles();
      await getAllPaymentType();
      await getAllSplit();
      await getAllVaccinationItems();

      if (props.employeeId.length) {
        const model = EmployeeEntityModule.model;

        employeeForm.lastName = model.lastName ?? '';
        employeeForm.firstName = model.firstName ?? '';
        employeeForm.middleName = model.middleName ?? '';
        employeeForm.birthday = model.birthday ?? null;
        employeeForm.gender = model.gender ?? '';
        employeeForm.belongingToCitizenship = model.belongingToCitizenship ?? '';
        employeeForm.geoRegion = model.geoRegion ?? '';
        employeeForm.phone = formatPhoneWithBrackets(model.phone);
        employeeForm.email = model.email ?? '';
        employeeForm.partnerUuid = model.partnerUuid ?? '';
        employeeForm.competence = model.competence ?? [];
        employeeForm.lmk = model.lmk ?? null;
        employeeForm.lmkNumber = model.lmkNumber ?? '';
        employeeForm.role = model.role ?? '';
        employeeForm.split = model.split ?? '';
        employeeForm.vaccinationStatus = model.vaccinationStatus ?? 'unknown';
        employeeForm.underageLink = model.underageLink ?? '';
        employeeForm.sleeping = model.sleeping;
        employeeForm.isTest = model.isTest;
        employeeForm.rocketWorkId = model.rocketWorkId?.toString() ?? '';
        employeeForm.rocketWorkStatus = model.rocketWorkStatus ?? '';
        employeeForm.humanRocketWorkStatus = model.humanRocketWorkStatus ?? '';
        employeeForm.inn = model.inn ?? '';
        employeeForm.bank = model.bank ?? '';
        employeeForm.paymentAccount = model.paymentAccount ?? '';
        employeeForm.paymentType = model.paymentType ?? '';
        employeeForm.cardNumber = formatCardNumber(model.cardNumber) ?? '';
        employeeForm.ks = model.ks ?? '';
        employeeForm.bic = model.bic ?? '';
        employeeForm.isSelfEmployed[0].value = model.isSelfEmployed;
        employeeForm.underage[0].value = model.underage;
        employeeForm.hasMobileApp = model.hasMobileApp;
        employeeForm.onDemandPaymentEnabled = model.onDemandPaymentEnabled;

        employeeForm.verificationUpdatedAt = model.verification.updatedAt ? new Date(model.verification.updatedAt) : null;

        employeeForm.docs.find((item) => item.key === 'snils')!.value = !!Number(model.verification.snils);
        employeeForm.docs.find((item) => item.key === 'inn')!.value = !!model.inn;
        employeeForm.docs.find((item) => item.key === 'lmk')!.value = !!model.lmk;
        employeeForm.docs.find((item) => item.key === 'passport')!.value = !!Number(model.verification.passport);

        employeeForm.checkReliability[0].value = !!Number(model.reliability.checked);
        employeeForm.reliabilityUpdatedAt = model.reliability.updatedAt ? new Date(model.reliability.updatedAt) : null;

        employeeForm.reliabilityItems.find((item) => item.key === 'debtNpd')!.value = !!Number(model.reliability.debtNpd);
        employeeForm.reliabilityItems.find((item) => item.key === 'criminalRecord')!.value = !!Number(
          model.reliability.criminalRecord
        );
        employeeForm.reliabilityItems.find((item) => item.key === 'trial')!.value = !!Number(model.reliability.trial);
        employeeForm.reliabilityItems.find((item) => item.key === 'dispute')!.value = !!Number(model.reliability.dispute);

        employeeForm.other.find((item) => item.key === 'hasMobileApp')!.value = !!Number(model.hasMobileApp);
        employeeForm.other.find((item) => item.key === 'onDemandPaymentEnabled')!.value = !!Number(
          model.onDemandPaymentEnabled
        );
        employeeForm.other.find((item) => item.key === 'sleeping')!.value = !!Number(model.sleeping);
        employeeForm.other.find((item) => item.key === 'isTest')!.value = !!Number(model.isTest);
        employeeForm.other.find((item) => item.key === 'isSharing')!.value = model.sharing === 'all';

        employeeForm.selfEmployedDay = model.selfEmployedDay ? new Date(model.selfEmployedDay) : null;

        state.sleeping = model.sleeping;
        state.geoRegion = model.geoRegion ?? '';
        state.timeZone = model.timeZone ?? 0;
        state.splitInfo = model.splitInfo;
        state.createdAt = model.createdAt;
        state.updatedAt = model.updatedAt;
        state.lastCameOut = model.lastCameOut ?? '';

        state.comments = EmployeeEntityModule.comments;
        state.commentsTotalCount = EmployeeEntityModule.commentsTotalCount;

        state.isLoading = false;
      } else {
        employeeForm.role = 'ROLE_EMPLOYEE';
        employeeForm.partnerUuid = userModule.partner?.uuid ?? '';
        employeeForm.paymentType = 'bank_account';
        state.isLoading = false;
      }

      filterCompetenceItems();

      // Дата апдейта верификации
      const unwatchVerification = watch(
        () => employeeForm.docs,
        () => {
          employeeForm.verificationUpdatedAt = new Date();
          unwatchVerification();
        },
        { deep: true }
      );

      // Дата апдейта надежности
      watch(
        () => employeeForm.checkReliability[0].value,
        (checkboxValue: boolean) => {
          employeeForm.reliabilityUpdatedAt = checkboxValue ? new Date() : null;
        },
        { deep: true }
      );

      watch(
        () => employeeForm.geoRegion,
        (regionId: string) => {
          const newTimeZoneValue = stateParams.geoRegionItems.find((region) => region.id === regionId)?.timeZone;

          if (newTimeZoneValue) {
            state.timeZone = newTimeZoneValue;
          }
        }
      );

      // Ставим проверку при наличии проблем
      watch(
        () => employeeForm.reliabilityItems,
        () => {
          const hasProblem = employeeForm.reliabilityItems.some((item) => item.value);
          if (hasProblem && !employeeForm.checkReliability[0].value) {
            employeeForm.checkReliability[0].value = true;
          }
        },
        { deep: true }
      );

      // Ставим проверку лмк
      watch(
        () => [employeeForm.lmk, state.timeZone],
        () => {
          const lmkDocumentItem = employeeForm.docs.find((item) => item.key === 'lmk');

          if (lmkDocumentItem) {
            const lmkExpireTimestamp = moment(employeeForm?.lmk ?? 0)
              .utcOffset(state.timeZone * 60, true)
              .endOf('day')
              .toDate()
              .getTime();

            lmkDocumentItem.value = lmkExpireTimestamp > Date.now();
          }
        },
        { deep: true }
      );
    });

    const reliabilityStatus = computed(() => {
      if (!employeeForm.checkReliability[0].value) {
        return 'Не проверен';
      }

      const hasProblem = employeeForm.reliabilityItems.some((item) => item.value);

      return hasProblem ? 'Условно надежен' : 'Благонадежен';
    });

    const showUnderage = computed(() => {
      if (employeeForm.birthday) {
        const years = moment().diff(employeeForm.birthday, 'years');
        const ADULT = 18;

        return years < ADULT;
      }

      return false;
    });

    const textFields = (): EmployeeTextFields => {
      const data: EmployeeTextFields = {
        lastName: employeeForm.lastName,
        firstName: employeeForm.firstName,
        belongingToCitizenship: employeeForm.belongingToCitizenship,
        phone: clearPhone(employeeForm.phone).slice(1),
        role: employeeForm.role,
        paymentType: employeeForm.paymentType,
        vaccinationStatus: employeeForm.vaccinationStatus,
        underage: employeeForm.underage[0].value ? '1' : '0',
        sleeping: employeeForm.other.find((item) => item.key === 'sleeping')!.value ? '1' : '0',
        isTest: employeeForm.other.find((item) => item.key === 'isTest')!.value ? '1' : '0',
        isSelfEmployed: employeeForm.isSelfEmployed[0].value ? '1' : '0',
        sharing: employeeForm.other.find((item) => item.key === 'isSharing')?.value ? 'all' : 'none',
        hasMobileApp: employeeForm.other.find((item) => item.key === 'hasMobileApp')!.value ? '1' : '0',
        onDemandPaymentEnabled: employeeForm.other.find((item) => item.key === 'onDemandPaymentEnabled')!.value ? '1' : '0',
        verification: {
          snils: employeeForm.docs.find((item) => item.key === 'snils')!.value ? '1' : '0',
          inn: employeeForm.docs.find((item) => item.key === 'inn')!.value ? '1' : '0',
          lmk: employeeForm.docs.find((item) => item.key === 'lmk')!.value ? '1' : '0',
          passport: employeeForm.docs.find((item) => item.key === 'passport')!.value ? '1' : '0',
          updatedAt: employeeForm.verificationUpdatedAt
            ? moment(employeeForm.verificationUpdatedAt).format('YYYY-MM-DD')
            : '',
        },
        reliability: {
          checked: employeeForm.checkReliability[0].value ? '1' : '0',
          updatedAt: employeeForm.reliabilityUpdatedAt ? moment(employeeForm.reliabilityUpdatedAt).format('YYYY-MM-DD') : '',
          debtNpd: employeeForm.reliabilityItems.find((item) => item.key === 'debtNpd')!.value ? '1' : '0',
          criminalRecord: employeeForm.reliabilityItems.find((item) => item.key === 'criminalRecord')!.value ? '1' : '0',
          trial: employeeForm.reliabilityItems.find((item) => item.key === 'trial')!.value ? '1' : '0',
          dispute: employeeForm.reliabilityItems.find((item) => item.key === 'dispute')!.value ? '1' : '0',
        },
      };

      if (employeeForm.middleName.length) {
        data.middleName = employeeForm.middleName;
      }

      if (employeeForm.gender.length) {
        data.gender = employeeForm.gender;
      }

      if (employeeForm.geoRegion.length) {
        data.geoRegion = employeeForm.geoRegion;
      }

      if (employeeForm.email.length) {
        data.email = employeeForm.email;
      }

      if (employeeForm.partnerUuid.length) {
        data.partner = employeeForm.partnerUuid;
      }

      if (employeeForm.birthday) {
        data.birthday = moment(employeeForm.birthday).format('YYYY-MM-DD');
      }

      if (employeeForm.lmk) {
        data.lmk = moment(employeeForm.lmk).format('YYYY-MM-DD');
      }

      if (employeeForm.lmkNumber.length) {
        data.lmkNumber = employeeForm.lmkNumber;
      }

      if (employeeForm.underage) {
        data.underageLink = employeeForm.underageLink;
      }

      if (employeeForm.role === 'ROLE_DEACTIVATED') {
        data.split = employeeForm.split;
      }

      if (employeeForm.competence.length) {
        employeeForm.competence.map((item: string) => {
          data[`competence[${item}]`] = item;
        });
      }

      if (employeeForm.inn.length) {
        data.inn = employeeForm.inn;
      }

      if (employeeForm.bank.length) {
        data.bank = employeeForm.bank;
      }

      if (employeeForm.paymentAccount.length) {
        if (!Number(employeeForm.paymentAccount)) {
          employeeForm.paymentAccount = '';
        }
        data.paymentAccount = employeeForm.paymentAccount;
      }

      if (employeeForm.cardNumber.length) {
        if (Number(employeeForm.cardNumber.replaceAll(' ', ''))) {
          data.cardNumber = employeeForm.cardNumber.replaceAll(' ', '');
        }
      }

      if (employeeForm.ks.length) {
        if (!Number(employeeForm.ks)) {
          employeeForm.ks = '';
        }
        data.ks = employeeForm.ks;
      }

      if (employeeForm.bic.length) {
        data.bic = employeeForm.bic;
      }

      return data;
    };

    //
    // списки для дропдаунов
    //
    const getAllCitizenShips = async (): Promise<void> => {
      try {
        const result = await getCitizenshipsAll();

        stateParams.citizenshipItems = result.map((citizenship) => ({
          id: citizenship.id.toString(),
          label: citizenship.name,
          icon: '',
          value: citizenship.id.toString(),
          disabled: false,
        }));
      } catch (error) {
        ResponseHandlerModule.showNotify({
          message: (error as CatchFormResponse).response.data.message ?? strings.UNKNOWN_ERROR,
          type: 'fail',
        });
      }
    };

    const getAllGeoRegions = async (): Promise<void> => {
      try {
        const result = await getRegionsGeoAll();

        stateParams.geoRegionItems = result.map((geoRegion: geoRegionEntity) => ({
          id: geoRegion.id.toString(),
          label: geoRegion.name,
          icon: '',
          value: geoRegion.id.toString(),
          disabled: false,
          timeZone: geoRegion.timeZone,
        }));
      } catch (error) {
        stateParams.geoRegionItems = [];

        ResponseHandlerModule.showNotify({
          message: (error as CatchFormResponse).response.data.message ?? strings.UNKNOWN_ERROR,
          type: 'fail',
        });
      }
    };

    const getAllPartners = async (): Promise<void> => {
      try {
        let partnersList: Omit<PartnerSelectListItem, 'isSupplier'>[];

        if (userModule.isSupervisor) {
          partnersList = await getAllPartnersList();
        } else {
          const defaultPartner = {
            uuid: (EmployeeEntityModule.model.partnerUuid || userModule.partner?.uuid) ?? '',
            legalName: (EmployeeEntityModule.model.partnerName || userModule.partner?.legalName) ?? '',
          };

          partnersList = [defaultPartner];
        }

        stateParams.partnerItems = Object.values(partnersList).map((partner: { uuid: string; legalName: string }) => ({
          id: partner.uuid,
          label: partner.legalName,
          icon: '',
          value: partner.uuid,
          disabled: false,
        }));
      } catch (error) {
        stateParams.partnerItems = [];

        ResponseHandlerModule.showNotify({
          message: (error as CatchFormResponse).response.data.message ?? strings.UNKNOWN_ERROR,
          type: 'fail',
        });
      }
    };

    const getAllCompetence = async (): Promise<void> => {
      try {
        const result = await getAllCompetenceList();
        stateParams.competenceItemsAll = result.map((competence: CompetenceEntity) => ({
          id: competence.id.toString(),
          name: competence.name,
        }));
      } catch (error) {
        stateParams.competenceItemsAll = [];

        ResponseHandlerModule.showNotify({
          message: (error as CatchFormResponse).response.data.message ?? strings.UNKNOWN_ERROR,
          type: 'fail',
        });
      }
    };

    const getAllRoles = async (): Promise<void> => {
      stateParams.roleItems = [
        {
          id: 'ROLE_EMPLOYEE',
          label: 'Активен',
          icon: '',
          value: 'ROLE_EMPLOYEE',
          disabled: false,
        },
        {
          id: 'ROLE_DEACTIVATED',
          label: 'Заблокирован',
          icon: '',
          value: 'ROLE_DEACTIVATED',
          disabled: false,
        },
        {
          id: 'ROLE_AWAITING',
          label: 'Ожидает',
          icon: '',
          value: 'ROLE_AWAITING',
          disabled: true,
        },
      ];
    };

    const getAllPaymentType = async (): Promise<void> => {
      stateParams.paymentTypeItems = [
        {
          id: 'bank_account',
          label: 'По реквизитам',
          icon: '',
          value: 'bank_account',
          disabled: false,
        },
        {
          id: 'card',
          label: 'По номеру карты',
          icon: '',
          value: 'card',
          disabled: false,
        },
        {
          id: 'sbp_account',
          label: 'СБП',
          icon: '',
          value: 'sbp_account',
          disabled: false,
        },
      ];
    };

    const getAllSplit = async (): Promise<void> => {
      try {
        const result = await getSplitAll();

        stateParams.splitItems = result
          .filter((split) => !split.isArchived || split.id.toString() === EmployeeEntityModule.model.split)
          .map((split) => ({
            id: split.id.toString(),
            label: split.name,
            icon: '',
            value: split.id.toString(),
            disabled: false,
          }));
      } catch (error) {
        ResponseHandlerModule.showNotify({
          message: (error as CatchFormResponse).response.data.message ?? strings.UNKNOWN_ERROR,
          type: 'fail',
        });
      }
    };

    const getAllVaccinationItems = async (): Promise<void> => {
      try {
        const result = await getVaccinationsAll();

        stateParams.vaccinationItems = result.map((vaccination: { type: string; humanType: string }) => ({
          id: vaccination.type,
          label: vaccination.humanType,
          icon: '',
          value: vaccination.type,
          disabled: false,
        }));
      } catch (error) {
        stateParams.vaccinationItems = [];

        ResponseHandlerModule.showNotify({
          message: (error as CatchFormResponse).response.data.message ?? strings.UNKNOWN_ERROR,
          type: 'fail',
        });
      }
    };

    //
    // методы для БИК
    //
    const updateSelectedBic = (selected: string): void => {
      stateParams.bicItems.map((item: BicSearchItem) => {
        if (item.value === selected) {
          employeeForm.bank = item.bank;
          employeeForm.ks = item.ks;
        }
      });
    };

    const prepareBicList = (banks: DaDataSearchItem[]): BicSearchItem[] => {
      return banks.map((bank: DaDataSearchItem) => {
        return {
          id: bank.bic,
          link: bank.name,
          value: bank.bic,
          ks: bank.correspondentAccount,
          bank: bank.name,
        };
      });
    };

    const searchBic = async (value: string): Promise<void> => {
      try {
        if (value && value.length === 0) {
          stateParams.bicItems = [];

          return;
        }

        stateParams.bicLoading = true;
        const result = await getSuggestionBanksByBic(value);
        stateParams.bicItems = prepareBicList(result);
        stateParams.bicLoading = false;
      } catch (error) {
        stateParams.bicItems = [];
      }
    };
    //
    // методы для поиска Банка
    //
    const updateSelectedBank = (selected: string): void => {
      stateParams.bankItems.map((item: BankSearchItem) => {
        if (item.value === selected) {
          employeeForm.bic = item.bic;
        }
      });
    };

    const prepareBankList = (banks: DaDataSearchItem[]): BankSearchItem[] => {
      return banks.map((bank: DaDataSearchItem) => {
        return {
          id: bank.name,
          link: bank.bic,
          value: bank.name,
          ks: bank.correspondentAccount,
          bic: bank.bic,
        };
      });
    };

    const searchBank = async (value: string): Promise<void> => {
      try {
        if (value && value.length === 0) {
          stateParams.bicItems = [];

          return;
        }

        stateParams.bankLoading = true;
        const result = await getSuggestionBanksByBic(value);
        stateParams.bankItems = prepareBankList(result);
        stateParams.bankLoading = false;
      } catch (error) {
        stateParams.bankItems = [];
      }
    };

    //
    //
    //
    const updateInn = (value: string): void => {
      if (!value.length) {
        employeeForm.isSelfEmployed[0].value = false;
      }
    };

    const rocketWorkDisableDiffDates = computed(() => EmployeeEntityModule.rocketWorkStatusSendBlockUntil - Date.now());
    const disableRocketWorkButton = () => {
      isDisabledRocketWorkButton.value = true;

      setTimeout(() => {
        isDisabledRocketWorkButton.value = false;
      }, rocketWorkDisableDiffDates.value);
    };

    const isDisabledRocketWorkButton = ref(false);

    if (rocketWorkDisableDiffDates.value > 0) {
      disableRocketWorkButton();
    }

    const sendRocketWork = async (): Promise<void> => {
      try {
        isDisabledRocketWorkButton.value = true;
        await sendRocketWorkRequest(props.employeeId);

        EmployeeEntityModule.blockSendRocketWorkStatus(props.employeeId);
        disableRocketWorkButton();

        ResponseHandlerModule.showNotify({ message: 'Успешно отправлен в Рокет Ворк', type: 'ok' });

        await EmployeeEntityModule.getById(props.employeeId);
      } catch (error) {
        isDisabledRocketWorkButton.value = false;
        ResponseHandlerModule.showNotify({
          message: (error as CatchFormResponse).response.data.message ?? strings.UNKNOWN_ERROR,
          type: 'fail',
        });
      }
    };

    //
    // методы для комментариев
    //
    const startCommentAdd = (): void => {
      if (!state.isCommentAdd) {
        state.isCommentAdd = true;
        state.isCommentEdit = false;
        state.commentText = '';
      }
    };

    const startCommentEdit = (id: string, text: string): void => {
      state.isCommentAdd = false;
      state.isCommentEdit = true;
      state.commentText = text;
      state.commentEditId = id;
    };

    const disableComment = (): void => {
      state.isCommentAdd = false;
      state.isCommentEdit = false;
      state.commentText = '';
      state.commentError = '';
      state.commentEditId = '';
    };

    const sendComment = async (): Promise<void> => {
      if (state.commentText.length < 3) {
        if (!state.commentText.length) {
          state.commentError = 'Поле не заполнено';
        } else {
          state.commentError = 'Минимальная длина комментария: 3 символа';
        }

        return;
      } else if (state.commentText.length > 1024) {
        state.commentError = 'Максимальная длина комментария: 1024 символа';

        return;
      }

      if (state.commentEditId) {
        await EmployeeEntityModule.editComment({
          id: state.commentEditId.toString(),
          employee: props.employeeId,
          text: state.commentText,
        });
      } else {
        await EmployeeEntityModule.addNewComment({
          employee: props.employeeId,
          text: state.commentText,
        });
      }

      state.comments = EmployeeEntityModule.comments;
      state.commentsTotalCount = EmployeeEntityModule.commentsTotalCount;
      disableComment();
    };

    const deleteComment = async (): Promise<void> => {
      await EmployeeEntityModule.deleteComment({
        id: state.commentEditId.toString(),
        employee: props.employeeId,
      });
      state.deleteCommentModalShown = false;
      state.comments = EmployeeEntityModule.comments;
      state.commentsTotalCount = EmployeeEntityModule.commentsTotalCount;
      disableComment();
    };

    const loadMoreComments = async (): Promise<void> => {
      await EmployeeEntityModule.commentsLoadMore(props.employeeId);

      state.comments = EmployeeEntityModule.comments;
      state.commentsTotalCount = EmployeeEntityModule.commentsTotalCount;
      disableComment();
    };

    const formatCommentDate = (date: string): string => {
      return dateFullFormat(date);
    };

    //
    // методы для дублей
    //
    const closeDuplicateModal = (): void => {
      state.deleteDoubleId = 0;
      EmployeeEntityModule.closeDuplicateModal();
    };

    const deleteDuplicate = (deleteDoubleId?: string): void => {
      if (deleteDoubleId) {
        EmployeeEntityModule.deleteDuplicate(deleteDoubleId).then(() => {
          closeDuplicateModal();
          save();
        });
      }
    };

    //
    // методы для сохранения/создания
    //
    const returnBefore = (): void => {
      emit('returnBefore');
    };

    const edit = async (): Promise<void> => {
      try {
        await EmployeeEntityModule.saveUpdate({
          id: props.employeeId,
          textFields: textFields(),
        });
      } catch (error) {
        console.error(error);
      }
    };

    const add = async (): Promise<void> => {
      try {
        const result = await EmployeeEntityModule.addNew({
          textFields: textFields(),
        });

        if (result && !result.message) {
          returnBefore();
        }
      } catch (error) {
        console.error(error);
      }
    };

    const save = async (): Promise<void> => {
      await v$.value.$validate();
      if (v$.value.$error) {
        return;
      }

      if (props.employeeId.length) {
        await edit();
      } else {
        await add();
      }
    };

    //
    // отфильтрованные компетенции
    //
    const filterCompetenceItems = (): void => {
      if (employeeForm.belongingToCitizenship.length === 0 || employeeForm.belongingToCitizenship === '135') {
        //
        // Россия
        //
        stateParams.competenceItemsFiltered = stateParams.competenceItemsAll.map(
          (competence: { id: string; name: string }) => ({
            id: competence.id.toString(),
            label: competence.name,
            icon: '',
            value: competence.id.toString(),
            disabled: false,
          })
        );

        return;
      }

      //
      // компетенции, доступные для текущего гражданства
      //
      const availableForCurrentCitizenship = stateParams.competenceItemsAll.map(
        (competence: { id: string; name: string }) => ({
          id: competence.id.toString(),
          label: competence.name,
          icon: '',
          value: competence.id.toString(),
          disabled: false,
        })
      );

      if (
        state.employeeId.length &&
        stateParams.competenceItemsAll.length &&
        !employeeForm.competence.length &&
        employeeForm.belongingToCitizenship.length
      ) {
        ResponseHandlerModule.showNotify({
          message: 'Назначьте компетенцию, соответствующую выбранному гражданству.',
          type: 'fail',
        });
      }

      stateParams.competenceItemsFiltered = availableForCurrentCitizenship;
    };

    return {
      v$,
      employeeForm,
      stateParams,
      state,

      text: strings,

      updateSelectedBic,
      prepareBicList,
      searchBic,

      updateSelectedBank,
      prepareBankList,
      searchBank,

      updateInn,
      sendRocketWork,
      isDisabledRocketWorkButton,

      startCommentAdd,
      startCommentEdit,
      disableComment,
      sendComment,
      deleteComment,
      loadMoreComments,
      formatCommentDate,

      closeDuplicateModal,
      deleteDuplicate,

      returnBefore,
      save,

      filterCompetenceItems,
      reliabilityStatus,
      showUnderage,
    };
  },
});
